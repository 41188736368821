import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import HeroLayout from '../components/heroLayout';
import Layout from '../components/layout';
import '../styles/style.scss';

const HomePage = () => {
    return (
        <Layout>
            <HeroLayout
                footer={
                    <p>
                        <strong>RankingFotowoltaiki.pl</strong> by iqcode.pl
                    </p>
                }
                withHeader={true}
            >
                <Helmet title="Ranking Fotowoltaiki - Strona w budowie">
                    <meta
                        name="description"
                        content="Ranking Fotowoltaiki to zestawienie firm zajmujących się realizacją instalacji fotowoltaicznych. Z nami znajdziesz najlepszą firmę do instalacji paneli słonecznych."
                    />
                </Helmet>
                <div className="container has-text-left is-max-desktop">
                    <img
                        className="ma"
                        src="/ranking-fotowoltaiki-logo-web.svg"
                        alt="Ranking Fotowoltaiki - logo"
                    />
                    <h1 className="title mt-6 h-acc has-text-centered">
                        Ranking Fotowoltaiki - już wkrótce
                    </h1>
                    <div className="columns">
                        <div className="column">
                            <h2 className="title mt-3">O serwisie</h2>
                            <p className="block">
                                W Rankingu Fotowoltaiki znajdziesz zestawienie najlepszych firm
                                zajmujących się instalacjami fotowoltaicznymi w Polsce. Z naszą
                                pomocą wybierzesz odpowiedniego wykonawcę do położenia paneli
                                słonecznych na Twoim domu lub budynku gospodarczym.
                            </p>
                            <p className="block">
                                Ranking Fotowoltaiki umożliwi Ci przejrzenie ofert firm, które
                                oferują instalacje fotowoltaiczne w Twojej okolicy oraz
                                zapoznanie się z niezależnymi opiniami ich klientów. Po
                                realizacji paneli słonecznych na Twoim budynku, będziesz miał
                                możliwość dodania własnej opinii na temat wykonawcy.
                            </p>
                        </div>
                        <div className="column">
                            <h2 className="title mt-3">Dla instalatorów</h2>
                            <p className="block">
                                Twoja firma zajmuje się instalacjami fotowoltaicznymi?
                                Zapraszamy do nawiązania współpracy przez zapisanie się do
                                naszego programu beta.
                            </p>
                            <p className="block">
                                Na kilka tygodni przed publicznym startem naszego serwisu,
                                umożliwimy Tobie{' '}
                                <strong>utworzenie publicznego profilu firmy</strong>, który
                                będzie pojawiał się w naszych rankingach. Start wersji beta
                                serwisu przewidywany jest na kwiecień 2021 roku.
                            </p>
                            <Link to="/zapisy">
                                <button className="button is-primary">Zapisz się</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </HeroLayout>
        </Layout>
    );
};

export default HomePage;
