import { Link } from 'gatsby';
import React from 'react';
import { useState } from 'react';

const Layout = ({ children }) => {
    const [state, setState] = useState(false);
    return (
        <>
            <nav
                className="navbar is-fixed-top"
                role="navigation"
                aria-label="main navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link className="navbar-item" to="/">
                            <img src="/ranking-fotowoltaiki-logo-web.svg" height={36} />
                        </Link>
                        <a
                            role="button"
                            className="navbar-burger burger"
                            aria-label="menu"
                            aria-expanded="false"
                            data-target="navbarBasicExample"
                            onClick={() => {
                                setState(!state);
                            }}
                        >
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                        </a>
                    </div>
                    <div
                        id="navbarBasicExample"
                        className={`navbar-menu ${state ? 'is-active' : ''}`}
                    >
                        <div className="navbar-start">
                            <Link to="/" className="navbar-item">
                                Strona główna
                            </Link>
                            <Link to="/zapisy" className="navbar-item">
                                Zapisy
                            </Link>
                        </div>
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <div className="buttons">
                                    <Link to="/zapisy" className="button is-primary">
                                        <strong>Zapisz się</strong>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {children}
        </>
    );
};

export default Layout;
